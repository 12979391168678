import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useUpdateCart } from "../../hooks/useManageCart";
import css from "./CartThumbnail.module.css";
import QuantityList from "./QuantityList";
import { IN_STOCK, OUT_OF_STOCK } from "../products/Constants";
import { getFormattedPrice } from "../../utils";
import ProductUneligible from "../checkout/ProductUneligible";
import { useNavigate } from "react-router-dom";
import Image from "../shared/Image";
import { getHasImpersonate, checkPermission } from "../../utils/jwt";
import { PERMISSSIONS } from "../checkout/Constants";

function CartThumbnail({ data }) {
  const mutation = useUpdateCart();
  const navigate = useNavigate();
  const hasImpersonate = getHasImpersonate();
  const hasEditPerm = checkPermission(PERMISSSIONS.EDIT_ORDER_ITEM);
  const disableEdit = hasImpersonate && !hasEditPerm;

  const handleRemove = (evt) => {
    evt.preventDefault();
    mutation.mutate({
      itemsRemoved: [data?.sku],
      rewardPoints: 0,
      couponCode: "",
      shipVia: "",
      shippingRate: "",
      shipName: "",
      discount: 0,
      isPercent: false,
    });
  };

  const handleProductClick = () => {
    if (data?.active) {
      navigate(`/products/${data?.sku}`);
    }
  };

  const cursor = data?.active ? "pointer" : "default";

  return (
    <Grid container mt={1} className={css["cart-thumbnail"]}>
      <Grid item xs={5} sm={3} p={1}>
        <div
          style={{
            width: "100%",
            height: "100%",
            cursor: cursor,
          }}
        >
          <Image
            src={`${data?.thumbnail}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${data?.thumbnail}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={data?.shortName}
            loading="lazy"
            className={css["cart-thumbnail-image"]}
            onClick={handleProductClick}
          />
        </div>
      </Grid>
      <Grid item xs={7} sm={9} className={css["cart-thumbnail-info"]}>
        <Grid item xs={12} display="flex" flexDirection="row" pt={1}>
          <p
            style={{
              color: "primary",
              alignItems: "left",
            }}
          >
            {data?.shortName} ({data?.sku})
          </p>
          {!data?.rewardEligible && <ProductUneligible />}
        </Grid>
        <Grid
          item
          xs={12}
          sm={11}
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Grid item xs={11} sm={6} display="flex" flexDirection="row">
            {data?.inventory && data?.inventory > 0 && (
              <>
                <Typography color="secondary" align="left">
                  Qty:
                </Typography>
                <QuantityList item={data} />
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            display="flex"
            justifyContent="flex-end"
            flexWrap="wrap"
          >
            <CartItemPrice item={data} precision={2} />
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={11} justifyContent="space-between">
          <p
            style={{
              color: data?.inventory ? "var(--blue)" : "var(--danger)",
              textAlign: "left",
              paddingBottom: "4px",
            }}
          >
            {data?.inventory && data?.inventory > 0 ? IN_STOCK : OUT_OF_STOCK}
          </p>
          <Typography align="left" fontSize="14px" pb="4px">
            <Link
              disabled={disableEdit}
              component="button"
              color="#E71414"
              underline="none"
              onClick={handleRemove}
              style={{
                cursor: "pointer",
                height: "auto",
                color: disableEdit ? "var(--gray-400)" : "",
              }}
            >
              Remove
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

CartThumbnail.propTypes = {
  data: PropTypes.object,
};

const CartItemPrice = ({ item, precision }) => {
  if (!(item?.inventory > 0)) {
    return null;
  }

  if (item.quantity === 1) {
    return SingleItemPrice({ item, precision });
  } else {
    return MultiItemPrice({ item, precision });
  }
};

const SingleItemPrice = ({ item, precision }) => {
  if (item?.isFree) {
    return (
      <Typography color="secondary" pb="unset">
        FREE
      </Typography>
    );
  }

  return (
    <Typography color="secondary" pb="unset">
      {`$${getFormattedPrice(item?.discountedAmount, precision)}`}
    </Typography>
  );
};

const MultiItemPrice = ({ item, precision }) => {
  return (
    <>
      <Typography color="secondary" fontSize="14px" pb="unset">
        <b>{`$${getFormattedPrice(item?.totalWithoutTax, precision)}\u00a0`}</b>
      </Typography>
      <Typography color="secondary" fontSize="14px" pb="unset">
        {`($${getFormattedPrice(item?.discountedAmount, precision)} each)`}
      </Typography>
    </>
  );
};

export default CartThumbnail;
