import css from "../../styles/ProductDetailsHeader.module.css";
import { StyledBodyLight } from "../../styled";

const ProductDetailsHeader = ({ data }) => {
  const { name, disabled, oldName } = data || {};
  return (
    <>
      <h1 style={{ paddingBottom: "unset" }}>
        {name} {disabled && <b>*</b>}
      </h1>
      {oldName && (
        <StyledBodyLight className={css.oldName}>
          ({`${oldName}`})
        </StyledBodyLight>
      )}
    </>
  );
};

export default ProductDetailsHeader;
